import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

/**
 * Retreives or adds a portal to the DOM
 */
export default function Portal(props) {
  const { children, root = document.body } = props
  if (typeof root === 'string') {
    root = document.querySelector(root)
    if (!root) {
      root = document.createElement('div')
      root.id = root
      document.body.appendChild(root)
    }
  }
  const [container] = useState(document.createElement('div'))
  useEffect(() => {
    root.appendChild(container)
    return () => root.removeChild(container)
  }, [])
  return ReactDOM.createPortal(children, container)
}
