import { useState, useEffect } from 'react'

/**
 * Returns true when ta media query is matched and false
 * when it is not.
 */
export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(false)
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const mq = window.matchMedia(query),
      mql = () => {
        setMatches(mq.matches)
      }
    mq.addListener(mql)
    setMatches(mq.matches)
    return () => mq.removeListener(mql)
  }, [query])
  return matches
}
