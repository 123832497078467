import React, { useLayoutEffect } from 'react'
import { observable, set, toJS, action } from 'mobx'

export const NavbarUIState = observable(
  {
    _history: [],
    get state() {
      return this._history.length ? toJS(this._history[0]) : {}
    },
    pushState(newState = {}) {
      this._history.unshift({
        ...this.state,
        ...newState,
      })
    },
    popState() {
      return this._history.shift()
    },
  },
  {
    pushState: action,
    popState: action,
  },
  {
    deep: true,
  }
)

/**
 * A react-hook way of configuring the component
 * @param {*} props
 */
export function useNavbarConfig(props) {
  useLayoutEffect(() => {
    NavbarUIState.pushState(props)
    // When unmounted, return navbar state to previous state
    return () => NavbarUIState.popState()
  }, [props])
}
/**
 * A prop-based component way to configure the navbar.
 * @param {*} props
 */
export default function NavbarConfig(props) {
  useLayoutEffect(() => {
    NavbarUIState.pushState(props)
    // When unmounted, return navbar state to previous state
    return () => NavbarUIState.popState()
  }, [props])
  return null
}
